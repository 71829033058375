import {Outlet, Link, NavLink} from 'react-router-dom';
import "./assets/css/Layout.css";
import Footer3D from "./work/assets/footer-3D.webp";
import { FaBars, FaHandshake, FaArrowAltCircleUp } from 'react-icons/fa';
import {FcAbout, FcBusinessContact, FcApproval, FcCopyright, FcCopyleft } from 'react-icons/fc';
import Eyes from './work/Eyes';

const Layout = () => {
	const showMenu = () => {
		var x = document.getElementById("myTopnav");
		  if (x.className === "topnav") {
			x.className += " responsive";
		  } else {
			x.className = "topnav";
		  }
  }
	const backToTop = () =>{
		// 👇️ scroll to top on page load
		window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
	}
	return(
	<>
	<nav>
	<div className="topnav" id="myTopnav">
		<NavLink to="/">SachinThakur.in</NavLink>
  
		<Link to="/">Home</Link>
  
  <NavLink to="/work">Work</NavLink>
  
  <Link to="/">
  <Eyes />
		<div style={{display:"flex", justifyContent:"center"}} className="notch-container">
		<div className="eye-container" style={{
			
		}}>
			<div className="c-logo">
			  <div className="c-logo__eye" style={{transform: "rotate(102.478deg)"}}>
				<div className="c-logo__iris"></div>
			  </div>
			</div>
		</div>
		</div>
  
  </Link>
  
  <NavLink to="/contact">Contact</NavLink>
  
  <a href="/blog">Blog</a>
  <a href="javascript:void(0);" className="icon" onClick={showMenu}>
    <FaBars />
  </a>
</div>
</nav>
<div id="top">
</div>
		<Outlet/>
		
		<div className="ctoa">
	<table>
	<tbody>
    	<tr>
        	<td width="40%">
				<img src={Footer3D} style={{width:"100%",marginTop:"-30px"}} />
			</td>
            <td>
            	<h2>I occasionally take on freelance opportunities.</h2>
                <p>Have an exciting project where you need some help?<br/>
Send me over a message, and let's chat.</p>
				<Link to="/contact" className="y-btn"> <FaHandshake /> Get in Touch</Link>
            </td>
        </tr>
		</tbody>
    </table>
</div>

<div className="footer-menu">
	<ul>
		<li><Link to="/work"> <FcAbout /> About Me</Link></li>
		<li><Link to="/contact"> <FcBusinessContact /> Contact</Link></li>
		<li><Link to="/privacy-policy"> <FcApproval /> Privacy Policy</Link></li>
		<li><Link to="/dmca"> <FcCopyleft /> DMCA</Link></li>
		<li><Link to="/blog/faq-new-freelancers"> New Freelancer?</Link></li>		
		<li style={{float:"right"}} className="mr-5"><button onClick={backToTop} ><FaArrowAltCircleUp />Back to Top</button></li>
	</ul>
</div>
<div className="container pt-4 pb-4 text-center">
<FcCopyright /> Do Not copy; All Rights Reserved by sachinthakur.in.
</div>

	</>
	);
}

export default Layout;	