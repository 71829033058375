import {Link} from 'react-router-dom';
import { FcApproval } from 'react-icons/fc';
import {useEffect} from 'react';
import useDocumentTitle from './components/useDocumentTitle';
const Dmca = ()=>{
	useDocumentTitle('DMCA','https://www.sachinthakur.in/me.webp', 'We are protected with DMCA');  
	return(
		<>
		<div className="container">
		<div className="row">
		<div className="col-sm-10">
		<h1>DMCA</h1>
			<a href="//www.dmca.com/Protection/Status.aspx?ID=5955f268-f11a-4809-a50c-9c9f99b1c314" title="DMCA.com Protection Status" className="dmca-badge"> <img src ="https://images.dmca.com/Badges/dmca-badge-w100-5x1-07.png?ID=5955f268-f11a-4809-a50c-9c9f99b1c314"  alt="DMCA.com Protection Status" /></a>  <script src="https://images.dmca.com/Badges/DMCABadgeHelper.min.js"> </script>
			
			<br/><br/> <p>
If I Have included some content that belong to you or your organization by mistake, I am sorry for that. I apologize for that and assure you that this wont be repeated in future. If you are rightful owner of the content used in our Website, Please mail us with your Name, Organization Name, Contact Details, Copyright infringing URL and Copyright Proof (URL or Legal Document) at <strong>scn.arn@gmail.com</strong></p>
<p>
<strong>I assure you that, I will remove the infringing content Within 48 Hours.</strong>
</p>
		</div>
		<div className="col-sm-2 justify-content-center align-self-center">
		<FcApproval style={{fontSize:"100px"}}/>
		</div>
		</div>
		</div>
		</>
	);
} 
export default Dmca;