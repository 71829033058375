import { FcCamcorderPro } from "react-icons/fc";
import BrowserFrame from "react-browser-frame";



function Work(props) {
  return (
	<>
      <h2><FcCamcorderPro/> | {props.title}</h2>
		<div className="scn-screen">
			<BrowserFrame url={'https://'+props.title}>
				<img src={props.singleImage} className="work-img" alt={props.title} />
			</BrowserFrame>
		</div>
      <br/><br/>
      <p>{props.desc}</p>
      <hr/>
    </>
  );
}

export default Work;
